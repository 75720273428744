<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'

/////////////////////
// Interface Props //
/////////////////////

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

// filters
const filter_content = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

const types = ref([
    {
        label: 'Text',
        value: 'text'
    },
    {
        label: 'HTML',
        value: 'html'
    }
])

const new_content = ref({
        id: '',
        de_text: '',
        de_html: '',
        fr_text: '',
        fr_html: '',
        de: '',
        fr: '',
        type: 'text'
    })

//////////////////
// Data Sources //
//////////////////

const content = ref()
const pre_save_content = ref()
const changes = ref(0)
const save_text = ref("Speichern")
const newContentDialog = ref(false)
const loadVersionDialog = ref(false)
const op = ref()
const versions = ref()
const translations = ref()
const selectedTranslation= ref();

const toggle = (event) => {
            op.value.toggle(event)
        }

const loadDeeplData = (lang, text, event, id) => {
    translations.value = []
    loader.value = true
    let payload = {
        "lang": lang.toUpperCase(),
        "text": text,
        "id": id,
        "field": lang
    }
    toggle(event)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/deepl-translate-variants", payload)
        .then(response => {
            translations.value = response.data
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const onTranslationSelect = (event) => {
    op.value.hide()
    for (let i = 0; i < content.value.length; i++) {
        if (content.value[i].id == event.data.id) {
            if(event.data.lang == "DE") {
                content.value[i].de = event.data.text
            } else {
                content.value[i].fr = event.data.text
            }
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({ severity: 'success', summary: 'Angepasst', detail: event.newValue, life: 3000})
        } 
    }
}

const get_base_data = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-content-blocks")
        .then(response => {
            content.value = response.data
            pre_save_content.value = JSON.parse(JSON.stringify(response.data))
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

onMounted(() => {
    get_base_data()
})

const getVersions = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-content-blocks-archive")
        .then(response => {
            versions.value = response.data
            loadVersionDialog.value = true
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    });
}

const loadVersion = async (filename) => {
    loader.value = true
    let file = {"filename": filename}
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/load-content-blocks-archive", file)
        .then(response => {
            content.value = response.data
            toast.add({ severity: 'success', summary: 'Archiv-Datei geladen', detail: filename + " wurde erfolgreich geladen", life: 3000})
            changes.value = 0
            save_text.value = "Speichern"
            loader.value = false
            loadVersionDialog.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    });
}

function onCellEditComplete(event) {
    let index = content.value.findIndex((obj) => obj.id == event.data.id);
    if(event.field == "id" && check_id_exists(event.newValue, index) && event.newValue.includes(".")){
        toast.add({ severity: 'error', summary: 'ID: ' + event.newValue + ' existiert!', detail: 'Bitte eine ID wählen die noch nicht existiert', life: 3000})
    }
    else{
        content.value[index][event.field] = event.newValue
        if(pre_save_content.value[index][event.field] != event.newValue) {
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({ severity: 'success', summary: 'Angepasst', detail: event.newValue, life: 3000})
        }
    }
}

const newContent = () => {
    new_content.value = {
        id: '',
        de_text: '',
        de_html: '',
        fr_text: '',
        fr_html: '',
        de: '',
        fr: '',
        type: 'text'
    }
    newContentDialog.value = true
}

const saveNewContent = () => {
    if(new_content.value.type == 'text'){
        new_content.value.de = new_content.value.de_text
        new_content.value.fr = new_content.value.fr_text
    }else{
        new_content.value.de = new_content.value.de_html
        new_content.value.fr = new_content.value.fr_html
    }
    let new_content_json = {id:'', de:'', fr:'', type:''}
    new_content_json['id'] = new_content.value.id
    new_content_json['de'] = new_content.value.de
    new_content_json['fr'] = new_content.value.fr
    new_content_json['type'] = new_content.value.type
    content.value.push(new_content_json)
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    newContentDialog.value = false
    filter_content.value['global'].value = new_content.value.id
    toast.add({ severity: 'success', summary: 'Neues Feld', detail: 'Neues Feld wurde erfolgreich hinzugefügt', life: 3000})
}

const saveContent = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/update-content-blocks", content.value)
        .then(response => {
            content.value = response.data
            newContentDialog.value = false
            toast.add({ severity: 'success', summary: 'Gespeichert', detail: new_content.value.id, life: 3000})
            changes.value = 0
            save_text.value = "Speichern"
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    });
}

const publishVersion = () => {
    confirm.require({
        message: 'Bist du sicher, dass du diese Version publizieren möchtest?',
        header: 'Version publizieren?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, publizieren',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/publish-content-blocks", content.value)
                .then(response => {
                    content.value = response.data
                    newContentDialog.value = false
                    toast.add({ severity: 'success', summary: 'Version publiziert', detail: "Diese Version wurde erfolgreich publiziert", life: 3000})
                    loader.value = false
                }).catch(error => {
                console.error("There was an error!", error.message);
            })
        }
    })
}

const check_id_exists = (id, index) => {
    for (let i = 0; i < content.value.length; i++) {
        if (i != index && content.value[i].id == id) {
            return true
        }
    }
    return false
}

const delete_data = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du das Feld: ' + id + ' löschen möchtest?',
        header: 'Feld: ' + id + ' löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Feld löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            let index = content.value.findIndex((obj) => obj.id == id);
            content.value.splice(index, 1)
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({ severity: 'warn', summary: 'Feld gelöscht!', detail: 'Feld ' + id + ' wurde gelöscht', life: 3000})
        }
    })
}

const reset_data = () => {
    content.value = JSON.parse(JSON.stringify(pre_save_content.value))
    changes.value = 0
    save_text.value = "Speichern"
}

const print_test = (output) => {
    console.log("content", output)
}

const test_change = () => {
    toast.add({ severity: 'success', summary: 'TEST', detail: new_content.value.type, life: 3000})
}

function bytesForHuman(bytes, decimals = 2) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    let i = 0
    for (i; bytes > 1024; i++) {
        bytes /= 1024;
    }
    return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
}

const dt_translations = ref()
const exportCSV = () => {
    dt_translations.value.exportCSV()
}

</script>

<style scoped>
.p-inputtext {
    opacity: 1!important;
}
.p-multiselect-token  {
    color: #ffffff!important;
    background-color: #2196F3;
}
.p-datatable-header {
    padding: 0px!important;
}

.non-float-label {
    color: #6c757d;
    font-size: 12px;
    padding-left: 0.5rem;
}

.hide-table-header thead {
    display: none;
}
</style>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Loader :loader="loader" />
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <div class="col-12 mb-2">
        <Toolbar>
            <template #start>
                <span class="p-input-icon-left mr-4">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_content['global'].value" placeholder="Suche" style="width: 300px" />
                </span>
                <Button v-if="changes != 0" v-tooltip.bottom="'Anpassungen zurücksetzen'" @click="reset_data" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                <Button :disabled="changes != 0 ? false : true" @click="saveContent" v-tooltip.bottom="'Anpassungen Speichern'" :label="save_text" class="w-auto mr-4 p-button-success" icon="pi pi-save" />
                <Button @click="publishVersion()" :disabled="changes == 0 ? false : true" class="mr-2 p-button-warning" v-tooltip.bottom="'Publizieren'" icon="pi pi-cloud-upload" />
                <Button @click="getVersions()" class="mr-2 p-button-help" v-tooltip.bottom="'Vorherige Version laden'" icon="pi pi-history" />
                <Button @click="newContent()" class="mr-2 p-button" v-tooltip.bottom="'Neues Feld hinzufügen'" icon="pi pi-plus" />
                <Button icon="pi pi-file-excel" class="bg-green-50 text-green-500 border-green-500 pi-button" label="Export" @click="exportCSV()" />
            </template>
        </Toolbar>
    </div>
    <DataTable ref="dt_translations" editMode="cell" @cell-edit-complete="onCellEditComplete" v-model:filters="filter_content" :value="content" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" :autoLayout="true">
        <Column field="id" header="ID" sortable style="width: 300px">
            <template #editor="{ data, field }">
                <InputText style="width:100%;"  v-model="data[field]" autofocus />
            </template>
            <template #body="{ data, field }">
                <span class="font-bold">{{ data[field] }}</span>
            </template>
        </Column>
        <Column field="de" header="DE" style="width: 40%">
            <template #editor="{ data, field }">
                <Editor  v-if="data.type == 'html'" v-model="data[field]" editorStyle="width: 100%">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                        </span>
                    </template>
                </Editor>
                <Textarea v-if="data.type == 'text'" style="width:100%;" :autoResize="true" v-model="data[field]" autofocus />
            </template>
            <template #body="{ data, field }">
                <div class="text-sm" v-if="data.type == 'html'" v-html="data[field]"></div>
                <div class="text-sm" v-if="data.type == 'text'">{{data[field]}}</div>
            </template>
        </Column>
        <Column field="fr" style="width: 40%">
            <template #editor="{ data, field }">
                <Editor  v-if="data.type == 'html'" v-model="data[field]" editorStyle="width: 100%">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                        </span>
                    </template>
                </Editor>
                <Textarea v-if="data.type == 'text'" style="width:100%;" :autoResize="true" v-model="data[field]" autofocus />
            </template>
            <template #body="{ data, field }">
                    <div class="text-sm" v-if="data.type == 'html'" v-html="data[field]"></div>
                    <div class="text-sm" v-if="data.type == 'text'">{{data[field]}}</div>
            </template>
        </Column>
        <Column field="type" header="Typ">
            <template #editor="{ data, field }">
                <Dropdown v-model="data[field]" :options="types" optionLabel="label" optionValue="value" />
            </template>
            <template #body="slotProps">
                <Chip v-if="slotProps.data.type == 'text'" label="Text" class="mr-1" icon="pi pi-align-left" />
                <Chip v-if="slotProps.data.type == 'html'" label="HTML" style="background: #0288D1; color:#FFF" class="mr-1" icon="pi pi-code" />
            </template>
        </Column>
        <Column field="type">
            <template #body="slotProps">
                <span style="white-space: nowrap;">
                    <Button @click="delete_data(slotProps.data.id)" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" />
                </span>
            </template>
        </Column>
    </DataTable>

    <!-- Dialogs -->
    <Dialog v-model:visible="newContentDialog" :style="{width: '650px'}" header="Feld hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="id" type="text" v-model="new_content.id" required />
                    <label for="id">ID</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown id="type" v-model="new_content.type" :options="types" optionValue="value" optionLabel="label" required />
                    <label for="type">Typ</label>
                </span>
            </div>
            <div class="field col-12 mt-3" v-if="new_content.type == 'text'">
                <span class="p-float-label">
                    <Textarea id="de_text" style="width:100%;" :autoResize="true" v-model="new_content.de_text" />
                    <label for="de_text">DE (Text)</label>
                </span>
            </div>
            <div class="field col-12 mt-3" v-if="new_content.type == 'text'">
                <span class="p-float-label">
                    <Textarea id="fr_text" style="width:100%;" :autoResize="true" v-model="new_content.fr_text" />
                    <label for="fr_text">FR (Text)</label>
                </span>
            </div>
            <div v-if="new_content.type == 'html'" class="field col-12 mt-3">
                <label for="de_html" class="non-float-label">DE (HTML)</label>
                <Editor v-model="new_content.de_html" editorStyle="width: 100%">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div v-if="new_content.type == 'html'" class="field col-12 mt-3">
                <label for="fr_html" class="non-float-label">FR (HTML)</label>
                <Editor id="fr_html" v-model="new_content.fr_html" editorStyle="width: 100%">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="saveNewContent()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="newContentDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="loadVersionDialog" :style="{width: '850px'}" header="Version laden" :modal="true" class="p-fluid">
        <div class="col-12">
            <DataTable :value="versions" :paginator="true" :rows="10" :rowsPerPageOptions="[10, 20, 50]" :rowHover="true">
                <Column field="blob_name" header="Datei" sortable>
                    <template #body="slotProps">
                        <i class="pi pi-file mr-2"></i><a class="mb-1" :href="slotProps.data.blob_url" target="_blank" style="white-space: nowrap;">{{slotProps.data.blob_name}}</a>
                    </template>
                </Column>
                <Column field="blob_size" header="Grösse">
                    <template #body="slotProps">
                        <span>{{bytesForHuman(slotProps.data.blob_size, 1)}}</span>
                    </template>
                </Column>
                <Column field="blob_updated" header="Datum">
                </Column>
                <Column>
                    <template #body="slotProps">
                        <Button @click="loadVersion(slotProps.data.blob_name)" icon="pi pi-history" class="p-button-rounded p-button-text p-button-warning" />
                    </template>
                </Column>
            </DataTable>
        </div>
        <div class="col-12 mt-3">
            <Button label="Abbrechen" @click="loadVersionDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
        </div>
    </Dialog>
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 650px" :breakpoints="{'960px': '75vw'}">
        <DataTable :value="translations" v-model:selection="selectedTranslation" selectionMode="single" :rows="3" @rowSelect="onTranslationSelect" responsiveLayout="scroll" >
            <Column field="type" style="width: 20%" styleClass="hide-table-header">
                <template #body="slotProps">
                    <strong>{{slotProps.data.type}}</strong>
                </template>
            </Column>
            <Column field="text" style="width: 80%">
                <template #body="slotProps">
                    {{slotProps.data.text}}
                </template>
            </Column>
        </DataTable>
    </OverlayPanel>
</template>