<script setup>
import { ref, onMounted, useSSRContext } from "vue";
import { useToast } from "primevue/usetoast";
import axios from "axios";
import { FilterMatchMode } from "primevue/api";
import { EnumStringBody } from "@babel/types";
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false);
const toast = useToast();
// filters
const filter_reminders = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
//////////////////
// Data Sources //
//////////////////
const reminders = ref();
const get_base_data = () => {
  axios
    .get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-reminders")
    .then((response) => {
      reminders.value = response.data
    })
    .catch((error) => {
      console.error("There was an error!", error.message);
    });
};
onMounted(() => {
  get_base_data();
});
const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
  <Toast />
  <div class="col-12 mb-2">
    <Toolbar>
      <template #start> </template>
      <template #end>
        <span class="p-input-icon-left mr-2" style="width: 300px">
          <i class="pi pi-search" />
          <InputText v-model="filter_reminders['global'].value" placeholder="Suche" />
        </span>
        <Button type="button" icon="pi pi-cog" aria-controls="overlay_menu" class="p-button-rounded bg-blue-500" />
      </template>
    </Toolbar>
  </div>
  <DataTable v-model:filters="filter_reminders" :value="reminders" :rows="20" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" :paginator="true" :autoLayout="true">
    <Column field="contact_name" header="Kunde" sortable>
      <template #body="slotProps">
        <Avatar icon="pi pi-envelope" class="mr-2" style="background-color:#2196F3; color: #ffffff" shape="circle" />
        <a :href="'https://office.bexio.com/index.php/kontakt/show/id/' + slotProps.data.contact_id" target="_blank" class="font-bold">{{ slotProps.data.contact_name }} ({{ slotProps.data.contact_email }})</a>
      </template>
    </Column>
    <Column header="Gesendet">
      <template #body="slotProps">
        {{ format_swiss_date(slotProps.data.send_date) }}
      </template>
    </Column>
    <Column field="invoice_number" header="Rechnung" sortable>
      <template #body="slotProps">
        <a :href="'https://office.bexio.com/index.php/kb_invoice/show/id/' + slotProps.data.invoice_id" target="_blank" class="font-bold">{{ slotProps.data.invoice_number }}</a>
      </template>
    </Column>
    <Column field="contact_email" header="Auftrag" sortable hidden> </Column>
    <Column>
      <template #body="slotProps">
        <a :href="slotProps.data.network_link" target="_blank">
          <i class="pi pi-envelope"></i>
        </a>
      </template>
    </Column>
  </DataTable>
</template>