
<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useAuthStore } from '@/core/store/AuthStore'

////////////
// System //
////////////

const channel = ref("krediflow")
const auth = useAuthStore()

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_users = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const users = ref()
const roles = ref()
const inviteUserDialog = ref(false)
const invite_email = ref()
const invite_email_status = ref(false)

onMounted( async () => {
    init_data()
})

const init_data = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/providebit/get-roles')
    .then(response => {
        roles.value = response.data
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/providebit/get-users')
        .then(response => {
            users.value = response.data
            loader.value = false
        })
    })
}

watch (invite_email, () => {
    invite_email_status.value = invite_email.value.match(/.*@.*\..*/) !== null ? true : false
})

const invite_user = () => {
    if (check_email_exists(invite_email.value.replace(/\s+/g, ''))) {
        toast.add({severity:'error', summary: 'Fehler', detail: 'Benutzer existiert bereits', life: 3000})
    }
    else
    {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/providebit/invite-email', {email: invite_email.value.replace(/\s+/g, ''), channel_name: channel.value})
        .then(response => {
            if (response.data.status === "success") {
                toast.add({severity:'success', summary: 'Erfolg', detail: 'Benutzer erfolgreich eingeladen', life: 3000})
                init_data()
                inviteUserDialog.value = false
            } else {
                toast.add({severity:'error', summary: 'Fehler', detail: 'Benutzer konnte nicht eingeladen werden', life: 3000})
            }
            loader.value = false
        })
    }
}

const check_email_exists = (email) => {
    return users.value.some(function(el) {
        return el.user_email === email
    })
}

const get_role_name = (role_id) => {
    return roles.value.find(role => role.level == role_id).name
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <div>
        <Toast />
        <Toolbar>
            <template #start>
                <span class="p-input-icon-left mr-3">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_users['global'].value" placeholder="Suche" style="width: 300px" />
                </span>
                <Button label="Benutzer einladen" icon="pi pi-plus" class="p-button" @click="inviteUserDialog = true" />
            </template>
        </Toolbar>
        <DataTable class="mt-3 inbox-datatable" v-model:filters="filter_users" :value="users" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="user_firstname" header="Vornamen">
                <template #body="slotProps">
                    <div v-if="slotProps.data.user_firstname">
                        <Avatar icon="pi pi-user" class="mr-3 bg-primary text-white" shape="circle" />
                        <span class="font-bold">{{ slotProps.data.user_firstname }} {{ slotProps.data.user_lastname }}</span>
                    </div>
                    <div v-if="!slotProps.data.user_firstname">
                        <Avatar icon="pi pi-envelope" class="mr-3 bg-orange-500 text-white" shape="circle" />
                        <span class="font-bold text-orange-500">Benutzer eingeladen</span>
                    </div>
                </template>
            </Column>
            <Column field="user_lastname" header="Nachnamen" hidden></Column>
            <Column field="user_email" header="E-Mail"></Column>
            <Column field="user_roles" header="Rollen">
                <template #body="slotProps">
                    <template v-for="item in slotProps.data.user_roles" :key="item">
                        <Chip :label="get_role_name(item)" icon='pi pi-users' class="ml-2" />
                    </template>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-text" @click="edit_user(slotProps.row)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-text" @click="delete_user(slotProps.row)" />
                </template>
            </Column>
        </DataTable>
        <!---------------------------------------------------->
        <!------------------ Invite User --------------------->
        <!---------------------------------------------------->
        <Dialog v-model:visible="inviteUserDialog" :style="{width: '700px'}" header="Benutzer einladen" :modal="true" class="p-fluid z-5">
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <span class="p-float-label">
                        <InputText id="email" type="text" v-model="invite_email" />
                        <label for="email">E-Mail</label>
                    </span>
                </div>
                <div class="field col-12 mt-3">
                    <div>
                        <Button :disabled="invite_email_status ? false : true" label="Benutzer einladen" @click="invite_user()" class="w-auto mr-2 mb-1 p-button" icon="pi pi-send" />
                        <Button label="Abbrechen" @click="inviteUserDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>