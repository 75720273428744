import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    DocumentIcon,
    QuestionMarkCircleIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon,ShoppingCartIcon,LanguageIcon
} from '@heroicons/vue/24/outline';

//https://vue-hero-icons.netlify.app/

export default [
{ name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false, order: 0 },
{ name: 'Webshop', href: '/dashboard/Webshop', icon: ShoppingCartIcon, current: false, order: 1 },
{ name: 'Translations', href: '/dashboard/Translations', icon: LanguageIcon, current: false, order: 3 },
{ name: 'Einstellungen', href: '/dashboard/Einstellungen', icon: CogIcon, current: false, order: 4 },
{ name: 'Logout', href: '/logout', icon: ArrowLeftOnRectangleIcon, current: false, order: 6 }];