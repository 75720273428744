<script setup>
import { reactive, ref, inject } from 'vue'
import LoadingSpinner from './UI/Loading-Spinner.vue';
import MainToast from './UI/Main-Toast.vue';
import CoreRouter from '../router/router'
import axios from 'axios';
import { parseJwt } from '@/core/var/jwt';
import { useCustomerStore } from '@/core/store/CustomerStore';
import { useAuthStore } from '@/core/store/AuthStore';


const logo = ref();
const IN_PRODUCTION = process.env.NODE_ENV;
const phone = ref();

const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;
const auth = useAuthStore();
const sent = ref(false);


const state = reactive({
    spinner: false,
    servererror: false,
    message: {
        show: false,
        message: 'Ein schwerer Fehler ist aufgetreten, bitte versuchen Sie es erneut',
        messagemode: 'error'
    },
});


const user_channel = ref(process.env.VUE_APP_NEURAXIS_CHANNEL_ID)

const Login = async () => {
    state.spinner = true;
    await new Promise(resolve => setTimeout(resolve, 2000));
    state.spinner = false;
    sent.value = true;
}

const getLogo = () => {
    return require(`../../customers/${appmode.customer}/assets/${appmode.assets.logo}`);
}

const goBack = () => {
    CoreRouter.go(-1);
}
</script>




<template>
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto " :src="getLogo()" alt="" />
            <h2 class="mt-4 text-center text-xl md:text-3xl font-bold tracking-tight text-gray-800">SMS-Login</h2>
            <div class="mt-2 text-center text-base text-gray-600">
                Snappy Dashboard<br />
                <div class="font-medium inline-flex items-center rounded-full bg-primary-300 px-2.5 py-0.5 text-xs text-white mt-2">{{ appmode.fullname }}</div>
            </div>
            
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-10">
                <form class="space-y-6" v-on:submit.prevent="Login">
                    <div v-if="sent == false">
                        <label for="phone" class="block text-sm font-medium text-gray-700">Mobil-Nummer</label>
                        <div class="mt-1">
                            <input id="phone" name="phone" type="phone" v-model="phone" autocomplete="phone" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm" />

                        </div>
                    </div>

                    <!-- <div>
                        <label for="password" class="block text-sm font-medium text-gray-700">Passwort</label>
                        <div class="mt-1">
                            <input id="password" name="password" type="password" v-model="password" autocomplete="current-password" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm" />
                        </div>
                    </div> -->

                    <Message severity="success" v-if="sent"><span class="">Eine Login-SMS wurde verschick</span>t</Message>

                    <div v-if="sent == false">
                        <button type="submit" class="flex w-full justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-100 focus:ring-offset-1">
                            <LoadingSpinner size="4" color="fill-primary-600" v-if="state.spinner" background-dark="dark:text-gray-100"></LoadingSpinner> SMS erhalten
                        </button>
                    </div>
                    <div class="flex items-center justify-between">
                        <!-- <div class="flex items-center">
                            <input id="keep_logged_in" v-model="keep_logged_in" name="keep_logged_in" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-primary-600" checked />
                            <label for="keep_logged_in" class="ml-2 block text-sm text-gray-900">eingeloggt bleiben</label>
                        </div> -->

                        <div class="text-sm">
                            <router-link to="/" class="font-medium text-primary-600 hover:text-primary-500">Zurück</router-link>
                        </div>
                    </div>
                </form>


            </div>
        </div>
    </div>
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6">
        <MainToast v-if="state.message.show" :mode="state.message.mode">{{ state.message.message }}</MainToast>
    </div>
</template>
