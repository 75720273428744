<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
// filters
const filter_orders = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const orders = ref()
const invoices = ref()
const total_difference = ref()
const total_paid = ref()
const total_unpaid = ref()
const total_invoices = ref()
const total_invoices_paid = ref()
const total_invoices_unpaid = ref()
const total_rates = ref()
const total_unpaid_overdue = ref()
const total_invoices_overdue = ref()
const contact = ref()
const showInvoices = ref(false)

const get_base_data = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-all-bexio-orders")
        .then(response => {
            orders.value = response.data
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}
onMounted(() => {
    get_base_data()
})
const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}
const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const get_invoices = (id, total) => {
    loader.value = true
    let payload = {"contact_id": id}
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/search-contact-invoices", payload)
        .then(response => {
            invoices.value = response.data
            total_difference.value = total - get_total_paid(invoices.value)
            total_paid.value = get_total_paid(invoices.value)
            total_unpaid.value = get_total_unpaid(invoices.value)
            total_invoices.value = invoices.value.length
            total_invoices_paid.value = get_total_invoices_paid(invoices.value)
            total_invoices_unpaid.value = total_invoices.value - total_invoices_paid.value
            total_rates.value = total
            total_unpaid_overdue.value = get_total_unpaid_overdue(invoices.value)
            total_invoices_overdue.value = get_total_invoices_overdue(invoices.value)
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/bexio-get-contact", payload)
                .then(response => {
                    contact.value = response.data
                    showInvoices.value = true
                    loader.value = false
                    })
            })
}
const get_total_unpaid = (invoices) => {
    let total_unpaid = 0
    for (let key in invoices) {
        total_unpaid += Number(invoices[key].total_remaining_payments)
    }
    return total_unpaid
}
const get_total_unpaid_overdue = (invoices) => {
    let total_unpaid = 0
    for (let key in invoices) {
        if(check_due_date(invoices[key].is_valid_to)) {
            total_unpaid += Number(invoices[key].total_remaining_payments)
        }
    }
    return total_unpaid
}
const get_total_invoices_overdue = (invoices) => {
    let total_invoices_overdue = 0
    for (let key in invoices) {
        if (invoices[key].kb_item_status_id == 8 && check_due_date(invoices[key].is_valid_to)) {
            total_invoices_overdue++
        }
    }
    return total_invoices_overdue
}
const get_total_paid = (invoices) => {
    let total_paid = 0
    for (let key in invoices) {
        total_paid += Number(invoices[key].total_received_payments)
    }
    return total_paid
}
const get_total_invoices_paid = (invoices) => {
    let total_invoices_paid = 0
    for (let key in invoices) {
        if (invoices[key].kb_item_status_id == 9) {
            total_invoices_paid++
        }
    }
    return total_invoices_paid
}

const check_due_date = (value) => {
    let today = new Date()
    let due_date = new Date(value)
    if (due_date.getTime() < today.getTime()) {
        return true
    } else {
        return false
    }
}

const difference_in_day = (value) => {
    let today = new Date()
    let due_date = new Date(value)
    let diff = Math.abs(due_date.getTime() - today.getTime())
    let diffDays = Math.ceil(diff / (1000 * 3600 * 24))
    return diffDays
}

const onInvoiceRowEdit = (e) => {
    console.log(e)
    if(invoices.value[e.index].kb_item_status_id == 9 && e.value != e.newValue){
        toast.add({ severity: 'error', summary: 'Rechnung schon bezahlt!', detail: 'Bezahlte Rechnungen können nicht mehr angepasst werden', life: 3000 })
    }
    if(invoices.value[e.index].kb_item_status_id == 8 && e.value != e.newValue){
        let invoice = invoices.value[e.index]
        invoice[e.field] = e.newValue
        invoices.value[e.index][e.field] = e.newValue
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/bexio-update-invoice", invoice)
            .then(response => {
                if(response.data.message == 'success'){
                    toast.add({ severity: 'success', summary: 'Rechnung angepasst', detail: 'Die Rechnung wurde erfolgreich angepasst', life: 3000 })
                }
            })
    }
}

const openBexioOrder = (id) => {
    window.open('https://office.bexio.com/index.php/kb_order/show/id/' + String(id) + '#invoices','_blank')
}
const openBexioInvoice = (id) => {
    window.open('https://office.bexio.com/index.php/kb_invoice/show/id/' + String(id), '_blank')
}

const open_url = (url) => {
    window.open(url, '_blank')
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2" style="width: 300px">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_orders['global'].value" placeholder="Suche" />
                    </span>
                </template>
                <template #end>
                    <!--<Button label="Bexio Statistiken"  @click="open_url('http://localhost:5000/providebit/get-bexio-metrics')" class="w-auto mr-1" type="button" icon="pi pi-file-excel" v-tooltip.left="'Bexio Metrics exportieren'" />-->
                    <Button label="Bexio Statistiken"  @click="open_url('https://api.snpy.ch/providebit/get-bexio-metrics')" class="w-auto mr-1" type="button" icon="pi pi-file-excel" v-tooltip.left="'Bexio Metrics exportieren'" />
                </template>
            </Toolbar>
        </div>
        <DataTable v-model:filters="filter_orders" :value="orders" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true">
            <Column field="id" header="Auftrag" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-file" class="mr-2" style="backgroundColor:var(--primary-500); color:var(--surface-0)"  shape="circle" />
                    {{slotProps.data.id}} - {{slotProps.data.firstname}} {{slotProps.data.lastname}} ({{slotProps.data.customer_nr}})
                </template>
            </Column>
            <Column header="Datum" sortable>
                <template #body="slotProps">
                    {{format_swiss_date(slotProps.data.order_date)}}
                </template>
            </Column>
            <Column field="lastname" hidden></Column>
            <Column field="firstname" hidden></Column>
            <Column field="customer_nr" hidden></Column>
            <Column field="title" header="Betrag" sortable>
                <template #body="slotProps">
                    {{format_currency(slotProps.data.total)}}
                </template>
            </Column>
            <Column field="id" header="">
                <template #body="slotProps">
                    <Button label="Rechnungen" icon="pi pi-file" class="p-button mr-2 w-auto" @click="get_invoices(slotProps.data.bexio_customer_id, slotProps.data.total)" />
                    <Button label="Bexio" @click="openBexioOrder(slotProps.data.id)" class="mr-2 p-button w-auto" style="background-color: #0d2f3b; color: #bbdc00; border: 1px solid #bbdc00" icon="pi pi-eye" />
                </template>
            </Column>
        </DataTable>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="showInvoices" :style="{width: '1200px'}" header="Details" :modal="true" class="p-fluid">
        <DataTable :value="invoices" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" editMode="cell" @cell-edit-complete="onInvoiceRowEdit" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <div class="col-12 formgrid grid">
                <div class="field col-6 font-bold">
                    {{contact.name_1}} {{contact.name_2}} <Badge :value="contact.nr" style="background-color: #dee2e6; color: #607d8b"></Badge> <span style="font-weight: normal;">{{contact.address}}, {{contact.postcode}} {{contact.city}}</span>
                </div>
                <div class="field col-6">
                    <a :href="'mailto:' + contact.mail">{{contact.mail}}</a> / <a :href="'tel:' + contact.phone_fixed">{{contact.phone_fixed}}</a>
                </div>
                <div class="field col-4 font-bold">
                    Raten Rechnungen: <Badge :value="String(total_invoices)" severity="info"></Badge>
                </div>
                <div class="field col-4 font-bold">
                    Bezahlte Rechnungen: <Badge :value="String(total_invoices_paid)" severity="success"></Badge> / <Badge :value="total_invoices" severity="info"></Badge>
                </div>
                <div class="field col-4 font-bold">
                    Unbezahlte Rechnungen: <Badge :value="String(total_invoices_unpaid)" severity="warning"></Badge>
                </div>
                <div class="field col-4 font-bold">
                    Bezahlt / Brutto: <Badge :value="format_currency(total_paid)" severity="success"></Badge> / <Badge :value="format_currency(total_rates)" severity="info"></Badge>
                </div>
                <div class="field col-4 font-bold">
                    Offen / Differenz: <Badge :value="format_currency(total_unpaid)" severity="warning"></Badge> / <Badge :value="format_currency(total_rates - total_paid)" severity="danger"></Badge>
                </div>
                <div class="field col-4 font-bold">
                    Überfällig: <Badge :value="total_invoices_overdue" severity="danger"></Badge> / <Badge :value="format_currency(total_unpaid_overdue)" severity="danger"></Badge>
                </div>
            </div>
            <Column field="document_nr" header="Rechnungs-Nr" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-file" class="mr-2" style="background-color:#2196F3; color: #ffffff" shape="circle" />
                    {{slotProps.data.document_nr}}
                </template>
            </Column>
            <Column field="is_valid_from" header="Datum" sortable>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    {{format_swiss_date(data[field])}}
                </template>
            </Column>
            <Column field="is_valid_to" header="Fällig bis" sortable>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    {{format_swiss_date(data[field])}}
                </template>
            </Column>
            <Column field="total" header="Betrag" sortable>
                <template #body="slotProps">
                    {{format_currency(slotProps.data.total)}}
                </template>
            </Column>
            <Column field="total_remaining_payments" header="Status" sortable>
                <template #body="slotProps">
                    <Badge v-if="Number(slotProps.data.total_remaining_payments) == 0" value="Bezahlt" severity="success" class="mr-2" v-tooltip.top="'Rechnung ist bezahlt'"></Badge>
                    <Badge v-if="Number(slotProps.data.total_remaining_payments) > 0 && !check_due_date(slotProps.data.is_valid_to)" value="Offen" severity="info" class="mr-2" v-tooltip.top="'Rechnung ist ausstehend'"></Badge>
                    <Badge v-if="check_due_date(slotProps.data.is_valid_to) && Number(slotProps.data.total_remaining_payments) > 0" :value="'Überfällig (' + difference_in_day(slotProps.data.is_valid_to) + ' Tage)'" severity="danger" v-tooltip.top="'Rechnung ist überfällig'"></Badge>
                </template>
            </Column>
            <Column field="id" header="">
                <template #body="slotProps">
                    <Button label="Anzeigen" @click="openBexioInvoice(slotProps.data.id)" class="w-auto mr-2 mb-1 p-button-info" style="background-color: #0d2f3b; color: #bbdc00" icon="pi pi-eye" />
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>