<script setup>
import { reactive, ref } from 'vue'
import LoadingSpinner from './UI/Loading-Spinner.vue';
import MainToast from './UI/Main-Toast.vue';
import CoreRouter from '../router/router'
import axios from 'axios';
import { parseJwt } from '@/core/var/jwt';
import { useCustomerStore } from '@/core/store/CustomerStore';
import { useAuthStore } from '@/core/store/AuthStore';
import { DevicePhoneMobileIcon } from '@heroicons/vue/20/solid';

const logo = ref();
const IN_PRODUCTION = process.env.NODE_ENV;
const email = ref();
const password = ref();
const keep_logged_in = ref(true);
const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;
const auth = useAuthStore();
const sms_mode = ref(false);

const state = reactive({
  spinner: false,
  servererror: false,
  message: {
    show: false,
    message: 'Ein schwerer Fehler ist aufgetreten, bitte versuchen Sie es erneut',
    messagemode: 'error'
  },
});

const user_channel = ref(process.env.VUE_APP_NEURAXIS_CHANNEL_ID)

const Login = async () => {
  state.spinner = true;

  let payload = {
    user_email: email.value,
    password: password.value,
    keep_logged_in: keep_logged_in.value,
    user_channel: user_channel.value,
  }

  if (payload.user_email && payload.password && payload.user_channel) {
    await axios
      .post("/dashboard/login", payload)
      .then((response) => {
        // Error Case
        if (response.data === false) {
          state.message.show = true;
          window.setTimeout(() => {
            state.spinner = false;
            state.message.show = false;
          }, 3000);
        } else {

          if (response.data.message && response.data.message === 'error') {

            state.message.show = true;
            state.message.mode = 'error';
            state.message.message = 'Entweder die E-Mailadresse oder das Passwort ist falsch';
            window.setTimeout(() => {
              state.spinner = false;
              state.message.show = false;
            }, 3000);
          } else {
            // Login
            auth.setAuth(parseJwt(response.data));
            localStorage.setItem(process.env.VUE_APP_NEURAXIS_TOKENNAME, response.data);
            state.spinner = false
            CoreRouter.push({ path: '/dashboard', replace: false })
          }
        }

      })
      .catch(function (error) {
        state.message.show = true;

        if (!IN_PRODUCTION) {
          if (error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)

          } else if (error.request) {
            console.log(error.request)
          } else {
            console.log("Error", error.message)
          }
        }
        window.setTimeout(() => {
          state.spinner = false
          state.message.show = false;
        }, 3000)


      })
  }
}

const getLogo = () => {
  return require(`../../customers/${appmode.customer}/assets/${appmode.assets.logo}`);
}

</script>

<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto " :src="getLogo()" alt="" />
      <h2 class="mt-4 text-center text-xl md:text-3xl font-bold tracking-tight text-gray-800">Login</h2>
      <div class="mt-2 text-center text-base text-gray-600">
        Snappy Dashboard<br />
        <div class="font-medium inline-flex items-center rounded-full bg-primary-300 px-2.5 py-0.5 text-xs text-white mt-2">{{ appmode.fullname }}</div>
      </div>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" v-on:submit.prevent="Login">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">E-Mail</label>
            <div class="mt-1">
              <input id="email" name="email" type="email" v-model="email" autocomplete="email" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm" />

            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">Passwort</label>
            <div class="mt-1">
              <input id="password" name="password" type="password" v-model="password" autocomplete="current-password" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm" />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input id="keep_logged_in" v-model="keep_logged_in" name="keep_logged_in" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-primary-600" checked />
              <label for="keep_logged_in" class="ml-2 block text-sm text-gray-900">eingeloggt bleiben</label>
            </div>

            <div class="text-sm">
              <router-link to="/forgotpasswd" class="font-medium text-primary-600 hover:text-primary-500">Passwort vergessen</router-link>
            </div>
          </div>

          <div>
            <button type="submit" class="flex w-full justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-100 focus:ring-offset-1">
              <LoadingSpinner size="4" color="fill-primary-600" v-if="state.spinner" background-dark="dark:text-gray-100"></LoadingSpinner> Einloggen
            </button>
          </div>
        </form>

        <div v-if="sms_mode">
          <div class="relative mt-10">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-200"></div>
            </div>
            <div class="relative flex justify-center text-sm font-medium leading-6">
              <span class="bg-white px-6 text-gray-900">Oder Login mit</span>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-1 gap-4 text-center">

            <router-link to="/snappy-sms" class="flex items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent w-1/2 mx-auto">
              <DevicePhoneMobileIcon class=" h-10 w-10" />
              <span class="text-sm font-semibold leading-6">SMS</span>
            </router-link>
          </div>
        </div>
      </div>

    </div>
  </div>

  <LinkIcon class=" h-10 w-10" />

  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6">
    <MainToast v-if="state.message.show" :mode="state.message.mode">{{ state.message.message }}</MainToast>
  </div>
</template>
